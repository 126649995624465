import { useCallback, useEffect, useState } from 'react';

import './App.scss';
import useSound from 'use-sound';
import bgMusic from './sounds/398791-feryjory-sleep-time.mp3';
import swipeSound from './sounds/147285-frank1100-fh-paper-swipe.mp3';

const manifest = [
  // "/static/media/398791__feryjory__sleep-time.wav",
  // "/static/media/147285-frank1100-fh-paper-swipe.mp3",
  "/images/slide-1.jpeg",
  "/images/slide-2.jpeg",
  "/images/slide-3.jpeg",
  "/images/slide-4.jpeg",
  "/images/slide-5.jpeg"
];

function App() {


  const [page, setPage] = useState(0);
  const [bgMusicPlays, setBgMusicPlays] = useState(false);


  // const [playBgMusic1] = useSound('./sounds/398791__feryjory__sleep-time.wav');
  // const [playswipeSound1] = useSound('/sounds/147285__frank1100__fh-paper-swipe-surface2-short-01.wav');
  const [playBgMusic1] = useSound(bgMusic, { loop: true });
  const [playswipeSound1] = useSound(swipeSound);

  const playBgMusic = useCallback(
    () => {
      if (!bgMusicPlays) {
        console.log('not playing. play now');
        playBgMusic1();
        setBgMusicPlays(true);
      };
    },
    [bgMusicPlays, playBgMusic1, setBgMusicPlays],
  );



  function nextPage() {

    playBgMusic();
    playswipeSound1();
    if (page === 6) {
      setPage(0);
    } else {
      setPage(page + 1);
    }
  }






  const [filesToPreload, setFilesToPreload] = useState(0);
  const [filesPreloaded, setFilesPreloaded] = useState(0);


  useEffect(() => {
    setFilesToPreload(manifest.length);

    let _filesPreloaded = 0;
    manifest.forEach((url, index) => {
      fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        _filesPreloaded = _filesPreloaded + 1;
        console.log('done', blob);
        setFilesPreloaded(_filesPreloaded);
      });
    })
    // eslint-disable-next-line
  }, [])


  return (
    <div className="App">
      {/* {filesPreloaded} / {filesToPreload} |  */}
      {
        filesPreloaded !== filesToPreload ?
          <div className="loading">
            {Math.round(filesPreloaded / filesToPreload * 100) + "%"}
          </div>
          :
          page === 0 ?
            <div><button className="play" onClick={nextPage}><img src="/images/play.png" alt="" /></button></div>
            :
            page <= 5 ?
              <div>
                <div className="slide"><img src={`/images/slide-${page}.jpeg`} alt="" /></div>

                <button className="next arrow" onClick={nextPage}><img src="/images/arrow.png" alt="" /></button>
              </div>
              :
              <div className="end">
                <h1>The End | הסוף</h1>
                <p><a target="_blank" rel="noreferrer" href="https://www.instagram.com/shireltornek/">Drawings: Shierel Tornek | <span className="nowrap">איורים: שיראל טורנק</span></a></p>
                <p><a target="_blank" rel="noreferrer" href="https://www.instagram.com/hafonton/">UI Design & Programming: Elron | <span className="nowrap">עיצוב ממשק ותכנות: אלרון</span></a></p>
                <p><a target="_blank" rel="noreferrer" href="https://freesound.org/people/feryjory/sounds/398791/">Music: Katarzyna Kujawa | <span className="nowrap">מוזיקה: קטרזינה קוג'יווה</span></a></p>
                <button className="restart next" onClick={nextPage}>Restart | מהתחלה</button>
              </div>
      }
    </div>
  );
}

export default App;
